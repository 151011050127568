/* Barrel file for element's styles that are shared across projects. */
@import "./list.css";
@import "./image.css";
@import "./input.css";
@import "./button.css";
@import "./loader.css";
@import "./link.css";
@import "./skeleton.css";
@import "./address.css";
@import "./separator.css";
@import "./heading.css";
@import "./fieldset.css";
@import "./details.css";
@import "./table.css";
@import "./abbr.css";
