/* To override material inline styles. */
/* stylelint-disable declaration-no-important */

.cart-dialog-container.cdk-overlay-pane {
  max-width: 100vw !important;
  height: 95vh;
  width: 100%;
  overflow: auto;
  box-shadow:  0 0 10px 0 var(--shadow-color);
}

.cart-dialog-container.cdk-overlay-pane
  .mat-mdc-dialog-container.cdk-dialog-container {
  padding: 0;
}

@media only screen and (min-width: 768px) {
  .cart-dialog-container.cdk-overlay-pane {
    width: 500px;
    height: 100vh;
  }
}
