.pboxc-table {
  width: 100%;
  font-size: var(--font-size-lg);
  border-collapse: collapse;
}

.pboxc-table th {
  padding-bottom: 10px;
  border-bottom: 2px solid var(--primary-light-color);
  text-align: start;
}

.pboxc-table tr {
  border-bottom: 1px dashed var(--action-border);
}

.pboxc-table tr td {
  padding: 16px 0;
}
