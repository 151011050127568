:root {
  --mdc-dialog-subhead-font: var(--font-family);
  --mdc-dialog-subhead-line-height: 1;
  --mdc-dialog-subhead-size: var(--font-size-xl);
  --mdc-dialog-subhead-tracking: 0;

  --mdc-dialog-supporting-text-font: var(--font-family);
  --mdc-dialog-supporting-text-tracking: 0;
  --mdc-dialog-supporting-text-weight: 500;
}

.mat-mdc-dialog-actions.mdc-dialog__actions {
  padding: 0 20px 20px 20px;
}

.basic-dialog-container {
  overflow: auto;
}
