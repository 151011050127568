input[type="text"],
input[type="email"],
input[type="password"],
input[type="search"],
input:not([type]),
mat-select,
.pboxc-input,
textarea {
  width: 100%;
  padding: 10px;
  color: inherit;
  border: 1px solid var(--input-border-color);
  border-radius: var(--input-border-radius);
  background-color: var(--input-background);
}

input[type="radio"] {
  margin: 0;
  accent-color: var(--radio-button-color);
}

mat-select, .pboxc-input {
  padding: 6px 10px;
}

mat-select:focus-visible {
  outline: auto;
}

mat-select .mat-select-trigger {
  padding: 10px;
  height: 100%;
}

mat-select .mat-select-arrow {
  color: var(--primary-color);
}

mat-optgroup .mat-optgroup-label {
  color: var(--primary-font-color);
  font-family: var(--font-family);
  font-weight: 900;
}

select,
.pboxc-autocomplete {
  --dropdown-position-from-right: 15px;

  appearance: none;
  width: 100%;
  color: var(--primary-font-color);
  background-image: url("/assets/icons/dropdown.svg");
  background-position: calc(100% - var(--dropdown-position-from-right)) center;
  background-repeat: no-repeat;
  background-size: 10px 4px;
}

select.pboxc-filter {
  font-size: inherit;
  font-weight: inherit;
  width: max-content;
  border: none;
  background: none;
}

.pboxc-autocomplete__clearable {
  background-image: none;
}

input:disabled,
textarea:disabled,
select:disabled,
.mat-select-disabled {
  background-color: var(--input-border-light-color);
  color: var(--faint-font-color);
}

.mat-select-disabled .mat-select-value {
  color: var(--faint-font-color);
}

textarea {
  resize: vertical;
  min-height: 40px;
  font-size: var(--font-size-lg);
}

/*
  Fix that removes auto-zooming on iOS devices.
  The issue is explained here: https://stackoverflow.com/questions/2989263/disable-auto-zoom-in-input-text-tag-safari-on-iphone.

  TL;DR: Safari on iOS considers inputs with text of font size less than 16 pixels too small for reading and applies zooming to them.
  The `webkit-overflow-scrolling: touch` feature only exists on iOS, so we are using it as selector for iOS devices.
*/
@supports (-webkit-overflow-scrolling: touch) {
  input, textarea {
    font-size: 16px;
  }
}
