:root {
  /*
    Please use the following template to add new variables, dark/light valuations are optional.

    --<name>-color: <color>;
    --<name>-contrast-color: <contrast-color>;
    --<name>-dark-color: <dark-color>;
    --<name>-dark-contrast-color: <dark-contrast-color>;
    --<name>-light-color: <light-color>;
    --<name>-light-contrast-color: <light-contrast-color>;
  */

  /* Default theme (Product Box) variables start. */
  /* Base colors. */
  --primary-color: #005536;
  --primary-contrast-color: var(--secondary-color);
  --primary-alternative-contrast-color: #ffffff;
  --primary-light-color: #a6cc9a;
  --primary-light-contrast-color: #ffffff;

  --secondary-color: #efcf16;
  --secondary-contrast-color: var(--primary-color);
  --secondary-dark-color: #f17441;
  --secondary-dark-contrast-color: #ffffff;

  /* General elements. */
  --link-color: var(--primary-color);
  --icon-color: var(--primary-color);
  --checkbox-checked-color: var(--primary-color);
  --radio-button-color: var(--primary-color);
  --tab-label-color: var(--primary-color);
  --tab-border-color: var(--secondary-color);
  --snackbar-background-color: var(--primary-color);
  --snackbar-color: var(--secondary-color);
  --text-button-color: var(--primary-color);
  --outlined-button-color: var(--primary-color);
  --outlined-thick-button-border-color: var(--primary-color);

  /* Specific elements. */
  --subscribe-button-active-background-color: var(--primary-light-color);
  --subscribe-button-highlighted-background-color: var(--primary-color);
  --subscribe-button-highlighted-color: var(--secondary-color);
  --subscribe-button-highlighted-active-background-color: var(--secondary-color);
  --subscribe-button-highlighted-active-color: var(--primary-color);
  --product-details-dialog-header-color: var(--primary-color);
  --product-card-price-color: var(--secondary-dark-color);
  --product-card-icon-color: var(--primary-color);
  --order-item-expand-icon-color: var(--primary-color);
  --products-separator-icon-color: var(--primary-color);
  --products-separator-line-color: var(--primary-light-color);
  --welcome-message-name-color: var(--primary-color);
  --welcome-page-title-color: var(--primary-color);
  --quantity-control-button-background-color: var(--primary-color);
  --footer-background-color: var(--primary-color);
  --layout-header-logo-size: 50px;
  --layout-footer-logo-size: 60px;
  --cart-items-badge-background-color: var(--primary-color);
  --cart-items-badge-color: var(--secondary-color);
  --order-totals-label-color: var(--primary-color);

  /* Default theme variables end. */

  --medium-color: #fafafa;

  --shadow-color: rgb(0 0 0 / 0.1);

  --accent-color: #243068;

  --background-color: #fefefd;
  --background-contrast-color: #a6cc9a1a;

  --secondary-background-color: #fafafa;
  --secondary-background-dark-color: #f6f6f6;
  --secondary-background-contrast-color: var(--background-color);

  --header-border-color: #c9c9be;
  --action-border-color: #cccccc;

  --input-border-color: #cacabd;
  --input-border-dark-color: #a8a89b;
  --input-border-light-color: #eeeeee;
  --input-border-radius: 5px;
  --input-background: #ffffff;
  --input-required-color: #e02020;

  --primary-font-color: #000000;
  --font-family: Fraunces;
  --light-font-color: #7a7a7a;

  --out-of-stock-color: #e02020;
  --error-color: #cc0000;
  --price-color: #f17441;

  --product-hero-background: linear-gradient(0deg, #f2f2ed 0%, #ffffff 100%);
  --product-detail-image-height: 250px;
  --product-detail-image-width: 300px;

  /* Color for anything that should not draw attention. */
  --faint-font-color: #666666;
  --faint-border-color: #666666;

  /* Font sizes */
  --font-size-xs: 0.7rem;
  --font-size-sm: 0.9rem;
  --font-size-md: 1rem;
  --font-size-lg: 1.15rem;
  --font-size-xl: 1.45rem;
  --font-size-xlt: 1.81rem;
  --font-size-2xl: 2.15rem;

  --dashed-border: 1px dashed var(--action-border-color);
}

/* Carolina Flavors theme. */
[data-theme="cf"] {
  /* Base colors. */
  --primary-color: #42b984;
  --primary-contrast-color: #ffffff;
  --primary-alternative-contrast-color: #ffffff;
  --primary-light-color: #a6cc9a;
  --primary-light-contrast-color: #ffffff;

  --secondary-color: #f05537;
  --secondary-dark-color: #694c41;

  /* General elements. */
  --link-color: var(--secondary-dark-color);
  --icon-color: var(--secondary-dark-color);
  --checkbox-checked-color: var(--secondary-color);
  --radio-button-color: var(--secondary-dark-color);
  --tab-label-color: var(--secondary-dark-color);
  --tab-border-color: var(--secondary-color);
  --snackbar-background-color: var(--secondary-dark-color);
  --snackbar-color: var(--primary-contrast-color);
  --text-button-color: var(--secondary-dark-color);
  --outlined-button-color: var(--faint-font-color);
  --outlined-thick-button-border-color: var(--action-border-color);

  /* Specific elements. */
  --subscribe-button-active-background-color: var(--primary-color);
  --subscribe-button-highlighted-background-color: var(--primary-color);
  --subscribe-button-highlighted-color: var(--primary-contrast-color);
  --subscribe-button-highlighted-active-background-color: var(--secondary-color);
  --subscribe-button-highlighted-active-color: var(--primary-contrast-color);
  --product-details-dialog-header-color: var(--secondary-dark-color);
  --product-card-price-color: var(--primary-color);
  --product-card-icon-color: var(--secondary-color);
  --order-item-expand-icon-color: var(--secondary-dark-color);
  --products-separator-icon-color: #e07a4d;
  --products-separator-line-color: #dddddd;
  --welcome-message-name-color: var(--primary-font-color);
  --welcome-page-title-color: var(--primary-font-color);
  --quantity-control-button-background-color: var(--secondary-dark-color);
  --footer-background-color: var(--secondary-dark-color);
  --layout-header-logo-size: 80px;
  --layout-footer-logo-size: 80px;
  --cart-items-badge-background-color: var(--secondary-dark-color);
  --cart-items-badge-color: var(--primary-contrast-color);
  --order-totals-label-color: var(--primary-font-color);
}

/* Suppermeals theme. */
[data-theme="sm"] {
  /* Base colors. */
  --primary-color: #225536;
  --primary-contrast-color: #ffffff;
  --primary-alternative-contrast-color: #ffffff;
  --primary-light-color: #a6cc9a;
  --primary-light-contrast-color: #ffffff;

  --secondary-color: #f05537;
  --secondary-dark-color: #f17441;

  /* General elements. */
  --link-color: #225538;
  --icon-color: var(--primary-color);
  --checkbox-checked-color: var(--primary-color);
  --radio-button-color: var(--primary-color);
  --tab-label-color: var(--primary-color);
  --tab-border-color: var(--secondary-color);
  --snackbar-background-color: var(--primary-color);
  --snackbar-color: var(--primary-contrast-color);
  --text-button-color: var(--primary-color);
  --outlined-button-color: var(--primary-color);
  --outlined-thick-button-border-color: var(--primary-color);

  /* Specific elements. */
  --subscribe-button-active-background-color: var(--primary-color);
  --subscribe-button-highlighted-background-color: var(--primary-color);
  --subscribe-button-highlighted-color: var(--primary-contrast-color);
  --subscribe-button-highlighted-active-background-color: var(--secondary-color);
  --subscribe-button-highlighted-active-color: var(--primary-contrast-color);
  --product-details-dialog-header-color: var(--primary-color);
  --product-card-price-color: #e07a4d;
  --product-card-icon-color: var(--primary-color);
  --order-item-expand-icon-color: var(--primary-color);
  --products-separator-icon-color: var(--secondary-color);
  --products-separator-line-color: #dddddd;
  --welcome-message-name-color: var(--primary-font-color);
  --welcome-page-title-color: var(--primary-font-color);
  --quantity-control-button-background-color: var(--primary-color);
  --footer-background-color: #225538;
  --layout-header-logo-size: 75px;
  --layout-footer-logo-size: 80px;
  --cart-items-badge-background-color: var(--primary-color);
  --cart-items-badge-color: var(--primary-contrast-color);
  --order-totals-label-color: var(--primary-font-color);
}
