.mat-mdc-tab:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline,
.mat-mdc-tab-link:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline {
  border-color: var(--tab-border-color);
  border-width: 4px;
}

.mat-mdc-tab:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label,
.mat-mdc-tab-link:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label,
.mat-mdc-tab:not(.mat-mdc-tab-disabled) .mdc-tab__text-label,
.mat-mdc-tab-link:not(.mat-mdc-tab-disabled) .mdc-tab__text-label {
  color: var(--tab-label-color);
}

.mat-mdc-tab-links,
.mat-mdc-tab-label-container {
  border-bottom: 1px solid var(--action-border-color);
}

.mdc-tab__text-label {
  font-size: var(--font-size-lg);
  font-weight: bold;
}

.mdc-tab:not(.mdc-tab--active) .mdc-tab__text-label {
  font-weight: 600;
}

@media only screen and (max-width: 768px) {
  .mdc-tab__text-label {
    font-size: var(--font-size-md);
  }
}
