.mat-mdc-menu-content.mat-mdc-menu-content:not(:empty) {
  min-width: 265px;
  padding: 0;
}

.mat-mdc-menu-item {
  font-family: inherit;
  font-weight: normal;
}

.products-layout-menu.mat-mdc-menu-panel {
  border-radius: 10px;
}
