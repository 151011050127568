:root {
  /*
    Please use the following template to add new variables, dark/light valuations are optional.

    --<name>-color: <color>;
    --<name>-contrast-color: <contrast-color>;
    --<name>-dark-color: <dark-color>;
    --<name>-dark-contrast-color: <dark-contrast-color>;
    --<name>-light-color: <light-color>;
    --<name>-light-contrast-color: <light-contrast-color>;
  */

  /* Global variables for styling layout. */
  --web-desktop-horizontal-spacing: 95px;
  --web-tablet-horizontal-spacing: 20px;
  --web-auth-layout-logo-size: 90px;

  --products-hero-height: 300px;

  --web-main-header-height: 90px;
}

/* Carolina Flavors theme. */
[data-theme="cf"] {
  --web-auth-layout-logo-size: 90px;
}

/* Suppermeals theme. */
[data-theme="sm"] {
  --web-auth-layout-logo-size: 135px;
}

