/* To override material inline styles. */
/* stylelint-disable declaration-no-important */

.mobile-product-details-dialog-container.cdk-overlay-pane {
  max-width: 100vw !important;
  height: 95vh;
  width: 100%;
  overflow: auto;
}

.mobile-product-details-dialog-container.cdk-overlay-pane
  .mat-mdc-dialog-container.cdk-dialog-container {
  padding: 0;
}
