/* To override material inline styles. */
/* stylelint-disable declaration-no-important */

.user-dialog-container.cdk-overlay-pane {
  max-width: 100vw !important;
  height: 95vh;
  width: 100%;
  overflow: auto;
}

.user-dialog-container.cdk-overlay-pane
  .mat-mdc-dialog-container.cdk-dialog-container {
  padding: 0;
}

@media only screen and (min-width: 768px) {
  .user-dialog-container.cdk-overlay-pane {
    width: 650px;
    height: 80%;
    border-radius: 10px;
  }
}

@media only screen and (min-width: 1025px) {
  .user-dialog-container.cdk-overlay-pane {
    width: 800px;
  }
}
