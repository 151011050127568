/* App-specific variables. */
@import "./variables.css";

/* Element-specific styles. */
@import "./elements/index.css";

/* You can add application-scoped global styles to this file, and also import other style files */
html {
  font-size: 14px;
}

body,
html {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
}

* {
  box-sizing: border-box;
}
