.mat-mdc-list-item.mat-mdc-list-option.mdc-list-item {
  height: auto;
  min-height: 48px;
  padding-top: 3px;
  padding-bottom: 3px;
}

.mat-mdc-list-item.mat-mdc-list-option.mdc-list-item .mdc-list-item__primary-text {
  white-space: normal;
}
