h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

h1, h2, h3, .h1, .h2, .h3 {
  line-height: 1.1;
  font-weight: 900;
}

h1, .h1 {
  font-size: var(--font-size-xl);
}

h2, .h2, h3, .h3 {
  font-size: var(--font-size-lg);
}


@media only screen and (min-width: 768px) {
  h1, .h1 {
    font-size: var(--font-size-2xl);
  }

  h2, .h2 {
    font-size: var(--font-size-xl);
  }
}
