button {
  cursor: pointer;
  color: inherit;
  line-height: 1.1;
}

button.basic,
a.basic,
button.text,
a.text,
button.skip_calendar_button,
a.skip_calendar_button,
button.primary,
a.primary,
button.outlined,
a.outlined {
  font-size: 1rem;
  line-height: 1.1;
  text-decoration: none;
  margin: 0;
  padding: 10px;
  border-radius: 999px; /* to make it stadium-shaped */
  font-weight: bold;
  transition: all 150ms ease-in-out;
  cursor: pointer;
  display: block;
  text-align: center;
}

a.primary_bigger,
button.primary_bigger,
a.outlined_bigger,
button.outlined_bigger,
a.basic_bigger,
button.basic_bigger {
  font-size: var(--font-size-lg, 1.15rem);
  padding: 15px 20px;
}

button.primary_wider {
  padding-left: 50px;
  padding-right: 50px;
}

button.basic:hover,
a.basic:hover,
button.text:hover,
a.text:hover,
button.primary:hover,
a.primary:hover,
button.outlined:hover,
a.outlined:hover {
  opacity: 0.8;
}

button.basic:active,
a.basic:active,
button.text:active,
a.text:active,
button.primary:active,
a.primary:active,
button.outlined:active,
a.outlined:active {
  transform: scale(0.99);
}

button.basic:disabled,
a.basic:disabled,
button.text:disabled,
a.text:disabled,
button.primary:disabled,
a.primary:disabled,
button.outlined:disabled,
a.outlined:disabled {
  opacity: 0.8;
  cursor: not-allowed;
  transform: scale(1);
}

button.basic,
a.basic {
  background-color: var(--background-color);
  color: var(--faint-font-color);
  border: 1px solid var(--input-border-color);
}

button.primary,
a.primary {
  background-color: var(--primary-color);
  border: 1px solid var(--primary-color);
  color: var(--primary-contrast-color);
}

button.icon,
a.icon {
  color: var(--icon-color);
  background-color: inherit;
  border: none;
  border-radius: 0;
  padding: 0;
  cursor: pointer;
}

button.outlined,
a.outlined {
  color: var(--outlined-button-color);
  background-color: transparent;
  border: 1px solid var(--action-border-color);
  font-weight: normal;
}

button.outlined_thick,
a.outlined_thick {
  border-color: var(--outlined-thick-button-border-color);
  font-weight: 900;
}

button.text,
a.text {
  display: inline-block;
  padding: 5px 10px;
  border: none;
  font-size: 1.1rem;
  color: var(--text-button-color);
  background-color: var(--background-color);
}

button.with-icon,
a.with-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
}

button.skeleton,
a.skeleton {
  padding-top: 15px;
  padding-bottom: 15px;
  border: none;
}

button.skip_calendar_button,
a.skip_calendar_button {
  display: inline-block;
  padding: 5px 10px;
  border: none;
  font-size: var(--font-size-xlt, 1.81rem);
  color: var(--text-button-color);
  background-color: var(--background-color);
}