/* To override material inline styles. */
/* stylelint-disable declaration-no-important */

.web-product-details-dialog-container.cdk-overlay-pane {
  max-width: 100vw !important;
  width: 100%;
  height: 100%;
  overflow: auto;
  border-radius: 10px;
}

.web-product-details-dialog-container.cdk-overlay-pane
  .mat-mdc-dialog-container.cdk-dialog-container {
  padding: 0;
}

@media only screen and (min-width: 1025px) {
  .web-product-details-dialog-container.cdk-overlay-pane {
    min-width: 0;
    width: 1150px;
    height: 90%;
  }
}
